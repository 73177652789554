

import AsyncStorage from "@react-native-async-storage/async-storage"
import GenericViewSetAPI from "../api/GenericViewSetAPI"
import { Alert, Linking, Platform } from "react-native"
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import * as ImagePicker from 'expo-image-picker';
import Constants from "expo-constants";
import * as WebBrowser from 'expo-web-browser';
import Config from "./Config";
import * as Contacts from 'expo-contacts';
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import compareVersions from 'compare-versions'
import { captureException } from "@sentry/react-native";
import * as Sentry from 'sentry-expo';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';


// enum TripPhase {
//   THINKING = 0,
//   PLANNING, 
//   BOOKING
// }

export const getContactListShareLink = (contactList) => {
  return `${BaseApiConfigProvider.getWebUrl()}/list/${contactList.id}`
}

export const getToken = async () => {
  return await AsyncStorage.getItem("token")
}

export const openWebUrl = async (url, useBrowser = false) => {
  if (useBrowser) {
    await WebBrowser.openBrowserAsync(url);
  } else {
    Linking.openURL(url)
  }
}

export const getNumberRepresentation = (number) => {
  if (number == -1) {
    return "unlimited"
  } else {
    return `${number}`
  }
}


export function getVersionString() {
  var string = `${Constants.expoConfig.runtimeVersion}-${Config.internalVersion}`
  const extra = Constants.expoConfig.extra
  if (extra && extra.appEnv) {
    if (__DEV__) {
      string += "-dev"
    } else {
      string += `-${extra.appEnv}`;
    }
  }
  return string;
}

export const getCurrentUser = async (token) => {
  if (token) {
    let userApi = new GenericViewSetAPI("user")
    let userResp = await userApi.nonStandard("GET", "me");
    return userResp;
  }
  return null;
}

export const isAccountNeedErrorMessage = (errorMsg) => {
  return errorMsg == "Authentication credentials were not provided."
}

export const getContactList = (reminder) => {
  if (reminder?.contact_list_recipient) {
    return { recipient_id: reminder?.contact_list_recipient, ...reminder.contact_list_recipient.contact_list }
  }
  return null
}

export const getContacts = (reminder) => {
  if (reminder?.contact_recipients) {
    return reminder?.contact_recipients.map(item => { return { recipient_id: item.id, ...item.contact } })
  }
  return null
}

export const pickImage = async () => {
  // No permissions request is necessary for launching the image library
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    aspect: [4, 3],
    quality: 1,
  });

  console.log(result);

  if (!result.canceled) {
    return result.assets[0];
  }

  return null;
};

export async function uploadImageAsync(file, user_id, storage=null) {
  // Why are we using XMLHttpRequest? See:
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob: Blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", file.uri, true);
    xhr.send(null);
  });
  if (!storage) {
    storage = getStorage();
  }

  console.log(file)
  console.log(blob)
  console.log("NOW LOOKING AT FILE REF")
  const fileRef = ref(storage, `/user/${user_id}/` + uuidv4());
  console.log('fileRef', fileRef)

  const uploadTask = uploadBytesResumable(fileRef, blob);

  return await new Promise((resolve, reject) => {
    uploadTask.on('state_changed',
      (snapshot) => {
        console.log("Progress", snapshot)
      },
      (error) => {
        captureSentryException(error)
        console.log("Error", error)
        reject(error)
      },
      async () => {
        try {
          //@ts-ignore
          blob.close()
        } catch (e) {
          captureSentryException(e)
          console.log(e)

        }
        console.log("Complete")
        const downloadUrl = await getDownloadURL(fileRef);
        console.log("Download url", downloadUrl)
        resolve(downloadUrl)
      }
    )
  })
}


export const getToString = (contacts, contactList, recipientCount, localContactsMap = null) => {
  var toStr = ""
  if (contactList) {
    let toStr = `${contactList.name}`;
    const remainingCount = recipientCount - 1
    if (remainingCount <= 0) {
      return toStr
    } else {
      return `${toStr} + ${remainingCount}`
    }
  } else {
    if (recipientCount == 0) {
      return toStr
    } else {
      if (!contacts) {
        return ""
      }
      const firstTwoStr = contacts.slice(0, 2).map(item => getContactName(item, true, localContactsMap)).join(", ")
      const remaining = recipientCount - contacts.length
      if (remaining > 0) {
        return `${firstTwoStr} + ${remaining}`
      } else {
        return `${firstTwoStr}`
      }
    }
  }
}

export function meetsMinimumAppVersion(config) {
  if (config) {
    const minVersion = config.min_internal_version;
    if (minVersion) {
      console.log("Comparing current version to min version of ", minVersion)
      const delta = compareVersions(minVersion, Config.internalVersion);
      if (delta === 1) {
        return false;
      }
    }
  }
  return true
}

function convertFormattedPhoneNumberToDigits(formattedNumber) {
  return formattedNumber.replace(/[^0-9]/g, '');
}


function getFormattedNumber(number) {
  var mobile = number.digits
  if (!mobile && number.number) {
    mobile = convertFormattedPhoneNumberToDigits(number.number)
  }
  if (mobile.length === 10) {
    mobile = `+1${mobile}`
  } else {
    if (!mobile.includes("+")) {
      mobile = `+${mobile}`
    }
  }
  return mobile
}

export function getLimitedText(text, limit) {
  if (text.length > limit) {
    return `${text.substring(0, limit)}...`
  }
  return text
}


export async function getPhoneBookContacts() {
  const { data } = await Contacts.getContactsAsync({
    fields: [Contacts.Fields.Emails, Contacts.Fields.PhoneNumbers, Contacts.Fields.FirstName, Contacts.Fields.LastName, Contacts.Fields.Image],
  })

  const newContacts = data.map(contact => {
    return {
      id: contact.id,
      firsName: contact.firstName,
      lastName: contact.lastName,
      name: contact.name,
      phone: contact.phoneNumbers?.length > 0 ? contact.phoneNumbers[0]?.digits : null,
      email: contact.emails?.length > 0 ? contact.emails[0]?.email : null,
      image: contact.imageAvailable ? contact.image.uri : null,
      mobile: contact.phoneNumbers?.length > 0 ? getFormattedNumber(contact.phoneNumbers[0]) : null,
      source: "phone"
    }
  })

  return newContacts
}


export async function canRecieveNotifications() {
  if (Platform.OS === "web") {
    return;
  }
  if (Platform.OS !== "ios") {
    return true;
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    return existingStatus == "granted"
  } else {
    return false
  }
}


export async function registerForPushNotificationsAsync(force = false) {
  let token;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      if (force) {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      } else {
        return null;
      }
    }
    if (finalStatus !== 'granted') {
      console.error('Failed to get push token for push notification!');
      return;
    }
    // Learn more about projectId:
    // https://docs.expo.dev/push-notifications/push-notifications-setup/#configure-projectid
    token = (await Notifications.getExpoPushTokenAsync({ projectId: "762b63a1-09f0-41d3-b1fa-eb6cf14d9478" })).data;
    return token
  } else {
    alert('Must use physical device for Push Notifications');
    return null
  }
}



export function getErrorString(error, defaultStr = null, statusCode = null) {
  console.log("error manager looking at")
  console.log(error)

  if (statusCode === 401) {
    return "An account is needed to perform that action"
  }
  if (error === null || error === undefined) {
    console.log("Error is null or undefined. Returning")
    if (defaultStr) {
      return defaultStr;
    }
    return "Sorry, something went wrong. We're looking into it. Try again later."
  }

  if (error.detail !== undefined) {
    console.log("Error has a detail!")
    return error.detail
  } else if (Array.isArray(error)) {
    return error[0]
  } else {
    try {
      console.log("Error has no detail")
      const fieldName = Object.keys(error)[0]
      const errorMessage = error[fieldName][0]
      return `${fieldName}: ${errorMessage}`
    }
    catch (e) {
      console.log("Everything failed. idk.")
      return defaultStr ?? "Sorry, something went wrong. We're looking into it. Try again later."
    }
  }
}

export function getErrorHandlePage(error, errorStr) {
  console.log("Handling error for ", error)
  if (error.statusCode === 401) {
    return { page: "Signup", param: {} }
  } else if (errorStr.includes("Invalid access.")) {
    var page = "Plans"
    let param: any = {}

    if (errorStr.includes("Feature")) {
      let split = errorStr.split(":")
      if (split.length > 1) {
        let sku = split[1].trim()
        param.feature = sku
      }
    }

    return { page: page, param: param }
  }
  return null
}


export function getSentryReleaseString() {
  var string = `reminderbase@${Constants.expoConfig.runtimeVersion}+1`
  return string;
}

export function handleRequestError(resp, pageProps = null, showAlert = false, defaultErrorStr = null) {
  const errorStr = getErrorString(resp.error, defaultErrorStr, resp.statusCode)
  const pageData = getErrorHandlePage(resp, errorStr)
  console.log("pageData from error", pageData)

  if (pageData && pageProps) {
    pageProps.navigation.push(pageData.page, pageData.param)
  }
  if (showAlert && !pageData) {
    Alert.alert("Error", errorStr)
  }
}

export function injectJavascript(src, async) {
  if (Platform.OS === "web") {
    injectJavascriptIntoWeb(null, src, async)
  }
}

export async function injectJavascriptIntoWeb(scriptText, src = null, async = false, textType = null, defer = null) {
  if (Platform.OS !== "web") {
    return
  }
  const script = document.createElement("script")
  if (textType) {
    script.setAttribute('type', 'text/javascript');
  }
  if (async) {
    script.setAttribute('async', '')
  }
  if (src) {
    script.setAttribute('src', src)
  }
  if (defer) {
    script.setAttribute('defer', '')
  }

  if (scriptText) {
    script.text = scriptText
  }

  const headList = document.getElementsByTagName("head");
  if (headList.length > 0) {
    const head = headList[0]
    head.appendChild(script)
  }
}

export async function captureSentryException(e) {
  if (Platform.OS == "web") {
    Sentry.Browser.captureException(e);
  } else {
    Sentry.Native.captureException(e)
  }
}


export async function waitFor(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function getContactName(contact, allowOtherLabels = false, localContactsMap = null) {
  if (contact?.name) {
    return contact.name
  }

  if (localContactsMap?.mobile && contact?.mobile) {
    const localContact = localContactsMap?.mobile[contact.mobile]
    if (localContact) {
      return localContact.name
    }
  }

  if (localContactsMap?.email && contact?.email) {
    const localContact = localContactsMap?.email[contact.email]
    if (localContact) {
      return localContact.name
    }
  }

  console.log("===ALLOW OTHER LABELS", allowOtherLabels)
  console.log("===CONTACT", contact)
  if (allowOtherLabels) {
    if (contact?.mobile) {
      return contact.mobile
    }
    if (contact?.email) {
      return contact.email
    }
  }
  return "Anonymous"
}

const camelToFlat = (camel) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ")

  let flat = ""
  camelCase.forEach(word => {
    flat = flat + word + " "
  })
  flat = flat.substring(0, 1).toUpperCase() + flat.substring(1, flat.length - 1)
  return flat
}

export const snakeCaseToFlat = (snake) => {
  const snakeCase = snake.split("_")

  let flat = ""
  snakeCase.forEach(word => {
    flat = flat + word + " "
  })
  flat = flat.substring(0, 1).toUpperCase() + flat.substring(1, flat.length - 1)
  return flat
}

export const stripAndSplit = (str, split_item) => {
  return str.replace(/ /g, '').split(split_item)
}

// export function meetsMinimumAppVersion(config) {
//     if (config) {
//       const minVersion = config.min_internal_version;
//       if (minVersion) { 
//           const delta = compareVersions(minVersion, Config.internalVersion);
//           if (delta === 1) { 
//               return false;
//           } 
//       }
//   }
//   return true
// }

// export function meetsInternalVersion(minVersion, returnIfNull) { 
//   if (minVersion) { 
//     const delta = compareVersions(minVersion, Config.internalVersion);
//     if (delta === 1) { 
//         return false;
//     } else { 
//       return true
//     }
//   } else { 
//     return returnIfNull
//   }
// }


// export const getViewDataForPhase = (phase: string) => {
//   if (phase === "thinking") {
//     return { color: "", text: "CONSIDERING" }
//   } else if (phase === "planning") {
//     return { color: "", text: "PLANNING" }
//   } else {
//     return { color: "", text: "BOOKING" }
//   }
// }

// export const getImageFromRef = async (ref, openShare=false) => {
//   try {
//       const uri = await captureRef(ref, {
//           format: 'png',
//           quality: 0.7
//       });
//       if (openShare) { 
//         await ReactNativeShare.open( uri );
//         // shareLink(uri)
//       }
//       return uri;
//   } catch (err) {
//       console.error(err)
//       return null;
//   }
// }

// export function textMatches(value, type, regex) { 
//   var re = null
// 	if (regex !== null) { 
// 		re = regex
// 	}

// 	if (type === 'alphanumeric') { 
// 		re = /^[a-zA-Z0-9_.-]+$/
// 	}

// 	if (type === 'numeric') { 
// 		re = /^[0-9.]+$/
// 	}

// 	if (type === "integer")  { 
// 	  re = /^[0-9]+$/
// 	}

// 	if (type === "alphabet")  { 
// 		re = /^[a-zA-Z]+$/
// 	}

//   if (re.exec(value) === null && value !== '') { 
// 		return false;
// 	} else { 
//     return true;
//   }
// }

// export function getVersionString() {
//   var string = `${Constants.expoConfig.runtimeVersion}-${Config.internalVersion}`
//   const extra = Constants.expoConfig.extra
//   if (extra && extra.appEnv) {
//     if (__DEV__) { 
//       string += "-dev"
//     } else { 
//       string += `-${extra.appEnv}`;
//     }
//   }
//   return string;
// }

// export function getSentryReleaseString() { 
//   var string = `co.roamapp.roam@${Constants.expoConfig.runtimeVersion}+1`
//   return string;
// }

// export function sentryCaptureException(e) { 
//   if (Platform.OS === "web") {
//     Sentry.Browser.captureException(e);
//   } else { 
//     Sentry.Native.captureException(e)
//   }
// }


// export function testOrProdProvider(testVal, prodVal){
//   const isTest = __DEV__ ? true : Constants.expoConfig.extra.appEnv === "preview" ? true : false
//   return isTest ? testVal : prodVal;
// }


// export function getRandomItem(items) {
//   return items[Math.floor(Math.random() * items.length)];
// }


// export function webOrNative(webFn, nativeFn) {
//   if (Platform.OS == "web" && webFn) {
//     return webFn()
//   } else if (nativeFn) {
//     return nativeFn()
//   } else {
//     return null;
//   }
// }

// export const getViewType = () => {
//   const window = Dimensions.get("window");
//   const screen = Dimensions.get("screen");
//   if (screen.width >= 1000) { 
//     return "desktop"
//   } else {
//     return "mobile"
// }
// }


// export function openLinkWebAware(url) { 
//   if (Platform.OS === "web") { 
//     Linking.openURL(url)
//   } else { 
//     WebBrowser.openBrowserAsync(url)
//   }
// }

// export const getRandomTheme = () => {
//   const colorMap = { ...theme.colors }
//   delete colorMap.darkText
//   delete colorMap.lightText
//   delete colorMap.text
//   delete colorMap.trueGray
//   delete colorMap.contrastThreshold
//   delete colorMap.black

//   const items = Object.keys(colorMap)
//   const item = getRandomItem(items);
//   return item
// }

// export async function shareLink(link: string) { 
//   try {
//     const options: any = {}
//     if (Platform.OS === "android") {
//       options.message = link
//     } else { 
//       options.url = link
//     }
//     const result = await RNShare.share(options);
//     console.log(result)
//     if (result.action === RNShare.sharedAction) {
//       if (result.activityType) {
//         // shared with activity type of result.activityType
//       } else {
//         // shared
//       }
//     } else if (result.action === RNShare.dismissedAction) {
//       // dismissed
//     }
//   } catch (error) {
//     alert(error.message);
//   }
// }

// export function injectJavascript(src, async) { 
//   if (Platform.OS === "web") {
//     injectJavascriptIntoWeb(null, src, async)
//   }
// }

// export async function injectJavascriptIntoWeb(scriptText, src=null, async=false, textType=null) { 
//   if (Platform.OS !== "web") { 
//     return
//   }
//   const script = document.createElement("script")
//   if (textType) { 
//     script.setAttribute('type', 'text/javascript');
//   }
//   if (async) { 
//     script.setAttribute('async', '')
//   }
//   if (src) {
//     script.setAttribute('src', src)
//   }
//   if (scriptText) { 
//     script.text = scriptText
//   }

//   const headList = document.getElementsByTagName("head");
//   if (headList.length > 0) { 
//       const head = headList[0]
//       head.appendChild(script)
//   }
// }


// export function getErrorString(error, defaultStr=null) {
//   console.log("error manager looking at")
//   console.log(error)
//   if (error === null || error === undefined) { 
//     console.log("Error is null or undefined. Returning")
//     if (defaultStr) { 
//       return defaultStr;
//     }
//     return "Sorry, something went wrong. We're looking into it. Try again later."
//   }

//   if (error.detail !== undefined) { 
//     console.log("Error has a detail!")
//     return error.detail
//   } else { 
//     try { 
//       console.log("Error has no detail")
//       const fieldName = Object.keys(error)[0]
//       const errorMessage = error[fieldName][0]
//       return `${fieldName}: ${errorMessage}`
//     } 
//     catch (e) { 
//       console.log("Everything failed. idk.")
//       return defaultStr ?? "Sorry, something went wrong. We're looking into it. Try again later."
//     }
//   } 
// }

// export function generateUUID() { // Public Domain/MIT
//   var d = new Date().getTime();//Timestamp
//   var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//       var r = Math.random() * 16;//random number between 0 and 16
//       if(d > 0){//Use timestamp until depleted
//           r = (d + r)%16 | 0;
//           d = Math.floor(d/16);
//       } else {//Use microseconds since page-load if supported
//           r = (d2 + r)%16 | 0;
//           d2 = Math.floor(d2/16);
//       }
//       return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
//   });
// }


export function showAlert(alertString) {
  if (Platform.OS === "web") {
    window.alert(alertString);
  } else {
    Alert.alert(alertString);
  }
}


// export function convertDataUrlToFile(dataUrl, filename) {
//   var arr = dataUrl.split(','),
//     mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[1]),
//     n = bstr.length,
//     u8arr = new Uint8Array(n);

//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }

//   return new File([u8arr], filename, { type: mime });
// }


// export function getDatesString(startDate, endDate) {
//   if (!startDate && !endDate) {
//     return ""
//   }
//   if (moment(startDate).isSame(endDate) || !endDate) {
//     return moment(startDate).format('ddd ll');
//   } else {
//     return moment(startDate).format('ddd ll') + " - " + moment(endDate).format("ddd ll")
//   }
// }


// export function getCountString(str, count) { 

//   if (!count || count == 0 || count > 1) { 
//     return str + 's'
//   }
//   return str
// }

export function buildURLQuery(obj) {
  if (obj) {
    return Object.entries(obj)
      .filter(pair => pair[1] !== undefined && pair[1] !== null)
      .map(pair => pair.map(encodeURIComponent).join('='))
      .join('&');
  } else {
    return ""
  }
}

// export const getActualImageUrl = (baseUrl: string, imageUrl?: string) => {
//   let actualImageUrl = imageUrl?.trim()
//   if (!actualImageUrl || actualImageUrl.startsWith('data')) return

//   if (actualImageUrl.startsWith('//'))
//     actualImageUrl = `https:${actualImageUrl}`

//   if (!actualImageUrl.startsWith('http')) {
//     if (baseUrl.endsWith('/') && actualImageUrl.startsWith('/')) {
//       actualImageUrl = `${baseUrl.slice(0, -1)}${actualImageUrl}`
//     } else if (!baseUrl.endsWith('/') && !actualImageUrl.startsWith('/')) {
//       actualImageUrl = `${baseUrl}/${actualImageUrl}`
//     } else {
//       actualImageUrl = `${baseUrl}${actualImageUrl}`
//     }
//   }

//   return actualImageUrl
// }

// export const getHtmlEntitiesDecodedText = (text?: string) => {
//   const actualText = text?.trim()
//   if (!actualText) return

//   return decode(actualText)
// }

// export const getContent = (left: string, right: string, type: string) => {
//   const contents = {
//     [left.trim()]: right,
//     [right.trim()]: left,
//   }

//   return contents[type]?.trim()
// }

// export const getDateHandleWeb = (date) => { 
//   if (!date) { 
//     return null
//   }
//   if (Platform.OS === "web") {
//       return moment(date).toDate()
//   } else { 
//       return date
//   }
// }

// export const getImageSize = (url: string) => {
//   return new Promise<Size>((resolve, reject) => {
//     Image.getSize(
//       url,
//       (width, height) => {
//         resolve({ height, width })
//       },
//       // type-coverage:ignore-next-line
//       (error) => reject(error)
//     )
//   })
// }

// // Functions below use functions from the same file and mocks are not working
// /* istanbul ignore next */
// export const getPreviewData = async (text: string, requestTimeout = 5000, proxyUrl = null) => {
//   const previewData: PreviewData = {
//     description: undefined,
//     image: undefined,
//     link: undefined,
//     title: undefined,
//   }

//   try {
//     const textWithoutEmails = text.replace(REGEX_EMAIL, '').trim()

//     if (!textWithoutEmails) return previewData

//     const link = textWithoutEmails.match(REGEX_LINK)?.[0]

//     if (!link) return previewData

//     let url = link

//     if (!url.toLowerCase().startsWith('http')) {
//       url = 'https://' + url
//     }

//     // eslint-disable-next-line no-undef
//     let abortControllerTimeout: NodeJS.Timeout
//     const abortController = new AbortController()

//     var fullUrl = url;

//     if (Platform.OS == 'web') {
//       fullUrl = proxyUrl.replace("{}", url)
//     }

//     const request = fetch(fullUrl, {
//       headers: {
//         'User-Agent':
//           'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36',
//       },
//       signal: abortController.signal,
//     })

//     abortControllerTimeout = setTimeout(() => {
//       abortController.abort()
//     }, requestTimeout)

//     const response = await request

//     clearTimeout(abortControllerTimeout)

//     previewData.link = url

//     const contentType = response.headers.get('content-type') ?? ''

//     if (REGEX_IMAGE_CONTENT_TYPE.test(contentType)) {
//       const image = await getPreviewDataImage(url)
//       previewData.image = image
//       return previewData
//     }

//     var html = await response.text()
//     if (Platform.OS == "web") {
//       html = html.replace(/\\"/g, '"');
//     }

//     if (!html) { console.error("NO PREVIEW") }
//     // Some pages return undefined
//     if (!html) return previewData

//     const head = html.substring(0, html.indexOf('<body'))
//     // Get page title
//     const title = REGEX_TITLE.exec(head)
//     previewData.title = getHtmlEntitiesDecodedText(title?.[1])

//     let matches: RegExpMatchArray | null
//     const meta: RegExpMatchArray[] = []
//     while ((matches = REGEX_META.exec(head)) !== null) {
//       meta.push([...matches])
//     }


//     const metaPreviewData = meta.reduce<{
//       description?: string
//       imageUrl?: string
//       title?: string
//     }>(
//       (acc, curr) => {
//         // Verify that we have property/name and content
//         // Note that if a page will specify property, name and content in the same meta, regex will fail
//         if (!curr[2] || !curr[3]) return acc

//         // Only take the first occurrence
//         // For description take the meta description tag into consideration
//         const description =
//           !acc.description &&
//           (getContent(curr[2], curr[3], 'og:description') ||
//             getContent(curr[2], curr[3], 'description'))
//         const ogImage =
//           !acc.imageUrl && getContent(curr[2], curr[3], 'og:image')
//         const ogTitle = !acc.title && getContent(curr[2], curr[3], 'og:title')

//         return {
//           description: description
//             ? getHtmlEntitiesDecodedText(description)
//             : acc.description,
//           imageUrl: ogImage ? getActualImageUrl(url, ogImage) : acc.imageUrl,
//           title: ogTitle ? getHtmlEntitiesDecodedText(ogTitle) : acc.title,
//         }
//       },
//       { title: previewData.title }
//     )

//     previewData.description = metaPreviewData.description
//     previewData.image = await getPreviewDataImage(metaPreviewData.imageUrl)
//     previewData.title = metaPreviewData.title

//     if (!previewData.image) {
//       let imageMatches: RegExpMatchArray | null
//       const tags: RegExpMatchArray[] = []
//       while ((imageMatches = REGEX_IMAGE_TAG.exec(html)) !== null) {
//         tags.push([...imageMatches])
//       }

//       let images: PreviewDataImage[] = []

//       for (const tag of tags
//         .filter((t) => !t[1].startsWith('data'))
//         .slice(0, 5)) {
//         const image = await getPreviewDataImage(getActualImageUrl(url, tag[1]))

//         if (!image) continue

//         images = [...images, image]
//       }

//       previewData.image = images.sort(
//         (a, b) => b.height * b.width - a.height * a.width
//       )[0]
//     }

//     return previewData
//   } catch {
//     return previewData
//   }
// }

// /* istanbul ignore next */
// export const getPreviewDataImage = async (url?: string) => {
//   if (!url) return

//   try {
//     const { height, width } = await getImageSize(url)
//     const aspectRatio = width / (height || 1)

//     if (height > 100 && width > 100 && aspectRatio > 0.1 && aspectRatio < 10) {
//       const image: PreviewDataImage = { height, url, width }
//       return image
//     }
//   } catch { }
// }

// export const oneOf =
//   <T extends (...args: A) => any, U, A extends any[]>(
//     truthy: T | undefined,
//     falsy: U
//   ) =>
//     (...args: Parameters<T>): ReturnType<T> | U => {
//       return truthy ? truthy(...args) : falsy
//     }

// export const REGEX_EMAIL = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g
// export const REGEX_IMAGE_CONTENT_TYPE = /image\/*/g
// // Consider empty line after img tag and take only the src field, space before to not match data-src for example
// export const REGEX_IMAGE_TAG = /<img[\n\r]*.*? src=["'](.*?)["']/g
// export const REGEX_LINK =
//   /((http|ftp|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/i
// // Some pages write content before the name/property, some use single quotes instead of double
// export const REGEX_META =
//   /<meta.*?(property|name)=["'](.*?)["'].*?content=["'](.*?)["'].*?>/g
// export const REGEX_TITLE = /<title.*?>(.*?)<\/title>/g



// export function copyAndSetMap(key, value, map) { 
//   const newMap = {...map}
//   newMap[key] = value
//   return newMap
// }

// export function copyAndDeleteKey(key, map) { 
//   const newMap = {...map}
//   delete newMap[key]
//   return newMap
// }