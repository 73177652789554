import { HStack, Heading, View, Text, Button, FlatList, Fab, Icon, Input, Pressable, Spinner, Alert as NBAlert, FormControl, Badge, ShareIcon, Link } from "native-base";
import { useCallback, useEffect, useState } from "react";
import Layout from "../components/Layout";
import RemindersList from "../components/RemindersList";
import Placeholder from "../Constants/Placeholder";
import { AntDesign, Entypo, Feather } from "@expo/vector-icons";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { canRecieveNotifications, getPhoneBookContacts, meetsMinimumAppVersion, openWebUrl, registerForPushNotificationsAsync } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import AnalyticsManager from "../utils/AnalyticsManager";
import { useFocusEffect } from "@react-navigation/native";
import ReminderListCell from "../components/ReminderListCell";
import AsyncStorage from "@react-native-async-storage/async-storage";
import InitialReminderCreateModal from "../components/InitialReminderCreateModal";
import * as StoreReview from 'expo-store-review';
import { Alert, Linking, Platform, Share } from "react-native";
import Data from "../Constants/Data";
import * as Contacts from 'expo-contacts';
import { setLocalContactsMap } from "../store";
import Toast from 'react-native-toast-message'
import * as Notifications from 'expo-notifications';
import Analytics from "../utils/AnalyticsManager";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";


const testNotificationData = [
    {
      request: {
        content: {
              title: "Trip to Kyoto, Japan",
              body: 'Victor is interested in joining! awdadawdawdaw dawdawd awd aw da wd aw d aw dawdawdawdawdaw',
              data: {
                link: "PublicProfile?username=jaytravels",
              }
            }
      }
    }
  ]

type FetchReminderType = "upcoming" | "past" | "template"

export default function RemindersScreen(props) {
    const remindersApi = new GenericViewSetAPI("reminder")
    const userApi = new GenericViewSetAPI("user")
    const contactApi = new GenericViewSetAPI("contact")

    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.user)   
    const config = useSelector((state: any) => state.config)
    const createdReminderId = props.route?.params?.createdReminderId

    var notificationListener = null
    var responseListener = null

    const userListPage = `https://${BaseApiConfigProvider.testOrProd("stage", "www")}.reminderbase.co/user_list/${user?.brand_name?.toLowerCase() ?? user?.first_name?.toLowerCase()}-${user?.id}`

    const [accountMetrics, setAccountMetrics] = useState(null)
    const [remindersResp, setRemindersResp] = useState([])
    const [upcomingReminders, setUpcomingReminders] = useState(null)
    const [templateReminders, setTemplateReminders] = useState(null)
    const [pastReminders, setPastReminders] = useState(null)
    const [reminderType, setReminderType] = useState<FetchReminderType>("upcoming")
    const [searchQuery, setSearchQuery] = useState(null)
    const [showInitialReminderCreate, setShowInitialReminderCreate] = useState(false)
    const [canShowNewReminderbase, setCanShowNewReminderbase] = useState(true)
    const [refreshing, setRefreshing] = useState(false)
    const [upcomingPage, setUpcomingPage] = useState(1)
    const [templatesPage, setTemplatesPage] = useState(1)
    const [pastPage, setPastPage] = useState(1)
    const [templatesRespObj, setTemplatesRespObj] = useState(null)
    const [pastRespObj, setPastRespObj] = useState(null)
    const [loadingMore, setLoadingMore] = useState(false)

    useEffect(() => {
        // checkCanShowAdStatus()
        if (Platform.OS !== "web") { 
            syncNonNameContacts()
        }
        
        const meetsMinVer = meetsMinimumAppVersion(config)
        if (!meetsMinVer) {
            props.navigation.replace("UpdateRequired")
            return;
        }

        getShowV2Ad()

        handleRegisterForPush()
        notificationListener = Notifications.addNotificationReceivedListener(notification => {
            handleNotificationRecieved(notification)
          });


        responseListener = Notifications.addNotificationResponseReceivedListener(response => {
            console.log('notification response recieved', response)
            const notification = response.notification;
            if (notification.request.content.body != null) { 
              let content = notification.request.content;
              if (content.data) {
                if (content.data.link) { 
                  let url = content.data.link as string
                //   const { path, queryParams } = Linking.parse(url);
                //   props.navigation.navigate(path, queryParams)
                } else if (content.data.url) { 
                    Linking.openURL(content.data.url as string)
                //   WebBrowser.openBrowserAsync(content.data.url as string)
                } else if (content.data.page) { 
                    props.navigation.navigate(content.data.page, content.data.pageProps)
                }
              }
            }
          });

        //   handleNotificationRecieved(testNotificationData[0])

        return () => {
            if (notificationListener && notificationListener.current) {
              Notifications.removeNotificationSubscription(notificationListener.current);
            }
            if (responseListener && responseListener.current) {
              Notifications.removeNotificationSubscription(responseListener.current);
            }
        };

    }, [])

    async function hydrateMemberMetrics() {
        const memberMetricsResp = await userApi.nonStandard("GET", "account_metrics")
        if (memberMetricsResp && !memberMetricsResp.error) {
            setAccountMetrics(memberMetricsResp)
        }
    }

    async function hydrateReminders(type: FetchReminderType = "upcoming", page=1, overwrite=true ) { 
        if (!type) { 
            type = reminderType
        }
        let query = {type: type, page: page, page_size: Data.pageSize}
        if (searchQuery) {
            query["title"] = searchQuery
        }
        console.log(query)
        if (type === "upcoming") {
            const remindersReps = await remindersApi.query(query)
            console.error(remindersReps)
            if (remindersReps && ! remindersReps.error) {
                setRemindersResp(remindersReps)
                if (overwrite) { 
                    setUpcomingReminders(remindersReps.results);
                    setUpcomingPage(1)
                } else {
                    setUpcomingReminders([...upcomingReminders, ...remindersReps.results])
                }
            }
        } else if (type == "template") {
            const templatesResp = await remindersApi.query(query)
             if (templatesResp && !templatesResp.error) { 
                setTemplatesRespObj(templatesResp)
                if (overwrite) { 
                    setTemplateReminders(templatesResp.results)
                    setTemplatesPage(1)
                } else {
                    setTemplateReminders([...templateReminders, ...templatesResp.results])
                }
            }
        } else if (type == "past") {
            const pastRemindersResp = await remindersApi.query(query)
            console.log( "Past reminders", pastRemindersResp)
            if (pastRemindersResp && !pastRemindersResp.error) { 
                setPastRespObj(pastRemindersResp)
                if (overwrite) { 
                    setPastReminders(pastRemindersResp.results)
                    setPastPage(1)
                } else { 
                    setPastReminders([...pastReminders, ...pastRemindersResp.results])
                }
            }
        }
    }

    const handleRegisterForPush = async() => {
        if (!user) { 
            return
        }
        //TODO: Fix -- double asking
        if (true) { 
            const token = await registerForPushNotificationsAsync()
            // Alert.alert("Notifications allowed!")
            console.log("Token", token)
            if (token) { 
                await userApi.update(user.id, {expo_push_token: token})
            }
        }
    }

    useEffect(() => {
        if (searchQuery === "") { 
            hydrateReminders(reminderType)
        }
    }, [searchQuery])

    const updateSearch = () => {
        hydrateReminders(reminderType)
    }

    useFocusEffect(useCallback(() => {
        AnalyticsManager.logEvent("view_page", {page: "reminders"})
        console.log("[Reminders] Reminders screen loading")
        hydrateMemberMetrics();
        handleIntialReminderCreate()
        Notifications.setBadgeCountAsync(0)

    }, []))

    const handleIntialReminderCreate = async() => {
        if (createdReminderId) {
            const initialCreateShown = await AsyncStorage.getItem("initialCreateShown")
            if (!initialCreateShown) { 
                setShowInitialReminderCreate(true)
            }
        }
    }

    useFocusEffect(useCallback(() => {
        console.log("HYDRATING VIA REMINDER TYPE")
        hydrateReminders(reminderType)
    }, [reminderType]))


    const hideV2Ad = async () => {
        await AsyncStorage.setItem("canShowNewReminderbase", "false")
        setCanShowNewReminderbase(false)
    }

    const getShowV2Ad = async () => {
        const canShow = await AsyncStorage.getItem("canShowNewReminderbase")
        let showAd = canShow !== "false"
        setCanShowNewReminderbase(showAd)
        return showAd
    }

    const syncNonNameContacts = async () => {
        const { status } = await Contacts.getPermissionsAsync();    
        if (status == "granted") {
            const contacts = await getPhoneBookContacts();
            const contactMobileMap = {}
            const contactEmailMap = {}
            contacts.forEach(c => contactMobileMap[c.mobile] = c)
            contacts.forEach(c => contactEmailMap[c.email] = c)

            dispatch(setLocalContactsMap({mobile: contactMobileMap, email: contactEmailMap, full: contacts}))

            // const noNameServerContacts = await contactApi.query({no_name: true})
            // let mobileToPhoneContactMap = {}
            // contacts.forEach(c => mobileToPhoneContactMap[c.phone] = c )
            // const updatedServerContacts = []
            // if (noNameServerContacts && !noNameServerContacts.error) { 
            //     for (var contact of noNameServerContacts.results) { 
            //         if (mobileToPhoneContactMap[contact.mobile]) { 
            //             contact.name = mobileToPhoneContactMap[contact.mobile].name
            //             updatedServerContacts.push(contact)
            //         }
            //     }
            // }
            // console.log("Found these no-name contacts locally", updatedServerContacts)
            // const requests = updatedServerContacts.map((item) => contactApi.update(item.id, {name: item.name}) ) 
            // await Promise.all(requests)
        }
    }

    function handleNotificationRecieved(notification) { 
        console.log('notification recieved', notification);
        const content = notification.request.content;
        if (true) { 
          Toast.show({
            type: 'info',
            text1: content.title,
            text2: content.body,
            onPress: () => {
              if (content.data.link) { 
                // let url = content.data.link as string
                // const { path, queryParams } = Linking.parse(url);
                // props.navigation.navigate(path, queryParams)
              } else if (content.data.url) { 
                // WebBrowser.openBrowserAsync(content.data.url as string)
                Linking.openURL(content.data.url as string)
              } else if (content.data.page) {
                props.navigation.navigate(content.data.page, content.data.pageProps)
              }
            }
          }
          );
        }
      }
    

    
    useFocusEffect(useCallback(() => {
        if (createdReminderId) {
           handleIntialReminderCreate()
        }
    }, [createdReminderId]))

    const getRemindersForType = () => {
        if (reminderType === "upcoming") {
            return upcomingReminders
        } else if (reminderType === "template") {
            return templateReminders
        }
        return pastReminders
    }

    const handleRefresh = async() => {
        setRefreshing(true)
        await hydrateReminders(reminderType, 1, true)
        setRefreshing(false)
    }   

    const getFullSuiteForReminderType = () => {
        if (reminderType === "upcoming") { 
            return {
                resp: remindersResp, 
                page: upcomingPage
            }
        } else if (reminderType == "template") { 
            return { 
                resp: templatesRespObj, 
                page: templatesPage
            }
        } else { 
            return {
                resp: pastRespObj, 
                page: pastPage
            }
        }
    }

    const updateReminderTypePage = (page) => {
        if (reminderType === "upcoming") { 
            setUpcomingPage(page)
        } else if (reminderType == "template") { 
            setTemplatesPage(page)
        } else { 
            setPastPage(page)
        }
    }

    const handleLoadMore = async() => {
        const fullSuite = getFullSuiteForReminderType()
        console.log("REMINDERS ON END REACHED", fullSuite)
        setLoadingMore(true)
        if (fullSuite.resp?.next) {
            const nextPage = fullSuite.page + 1
            await hydrateReminders(null, nextPage, false)
            updateReminderTypePage(nextPage)
        }
        setLoadingMore(false)
    }

    const goToDraftScreen = (reminder=null) => {
        console.error("PUSHING TO DRAFT")
        console.log("PUSHING REMINDER TO DRAFT", reminder)
        props.navigation.push("Draft", {reminder: reminder})
    }
    
    const onHelpCenterClick = () => {
        AnalyticsManager.logEvent("help_center_clicked")
        openWebUrl("https://app.archbee.com/public/PREVIEW-EzTZ_cWyPKKJ6N8CPpZjk/PREVIEW-5ioOavA9pg5exXfe7YPVE")
    }

    const renderReminder = (item) => {
        return <ReminderListCell onCellPress={() => goToDraftScreen(item.item)} reminder={item.item} />
    }

    const onNotificationConfirm = async () => {
        setShowInitialReminderCreate(false)
        registerForPushNotificationsAsync(true).then( token => userApi.update(user.id, {expo_push_token: token}))
        AsyncStorage.setItem("initialCreateShown", "true")
        await onIntialReminderCreateClose()
    }

    const onIntialReminderCreateClose = async() => {
        if (await StoreReview.hasAction()) {
            Analytics.logEvent("app_store_review_shown")
            await StoreReview.requestReview()
        }
        AsyncStorage.setItem("initialCreateShown", "true")
        setShowInitialReminderCreate(false)
    }

    const handleShareSubscribeLink = () => {
        AnalyticsManager.logEvent("share_subscribe_link")
        Share.share({ url : userListPage})
    }

    const renderItem = (item) => {  
        if (item.index === 0) { 
            let outOfString = null
            if (user?.out_of_email_at && user?.out_of_text_at) { 
                outOfString = "You're out of text and email credits"
            } else {
                if (user?.out_of_email_at) { 
                    outOfString = "You're out of email credits"
                }
                if (user?.out_of_text_at) { 
                    outOfString = "You're out of text credits"
                }
            }
            if (outOfString) { 
                return (
                    <Pressable onPress={() => props.navigation.navigate("Plans", {source: "out_of_limit"})}>
                        <NBAlert status="error" mt={2} mb={2}>
                        <HStack space={2}>
                            {/* <NBAlert.Icon /> */}
                            <Text>{outOfString + "."}</Text>
                            <Text bold>Upgrade</Text>
                        </HStack>
                    </NBAlert>
                    </Pressable>
                  );
            }
            return null;
        } 
        if (item.index == 1) { 
            return <View mt={4} mb={4}>
                {/* <Text fontSize="lg">Your subscribe link</Text> */}
                <FormControl>
                    <HStack mb={1} overflowX={"scroll"}>
                        {/* <Badge mr={1} fontSize={"xs"} colorScheme="green" rounded={"full"}>{user.plan_name}</Badge> */}
                        <Pressable onPress={() => props.navigation.navigate("Setting")}><Badge colorScheme={"green"} mr={1} rounded="full"><Text fontSize="xs">{user?.sms_limit} messages left</Text></Badge></Pressable>
                        <Pressable onPress={() => props.navigation.navigate("Plans", {source: "upgrade_home"})}><Badge mr={2} rounded="full">✨Upgrade plan</Badge></Pressable>
                    </HStack>
                    <Text fontSize="lg">Welcome</Text>
                    <Text fontSize={"sm"} mt={1}>People can register to recieve reminders from you by visiting your link</Text>
                    {/* <Button size="xs" variant="link" onPress={() => Share.share({ url : userListPage})}>Hide</Button>                     */}
                    <HStack mt={2} w="100%" alignItems={"center"}>
                        <Button size="sm" rounded="full" leftIcon={<ShareIcon />} mr={4} variant="subtle" onPress={handleShareSubscribeLink}>Share link</Button>
                        <Button size="sm" leftIcon={<Entypo name="book" />} rounded="full" variant="subtle" colorScheme={"green"}
                         onPress={() => onHelpCenterClick()}>Help center</Button>
                        {/* <Pressable><Text color="gray.600" fontSize="xs" underline>Get help</Text></Pressable> */}
                    </HStack>
                </FormControl>
                
            </View>
        }
        if (item.index === 2) { 
            const currReminders = getRemindersForType()
            return <View mt={2}>
                <HStack justifyContent={"space-between"} mb={2}>
                    <Heading size="xl">Reminders</Heading>
                    <Button variant="subtle" pt={0} pb={0} onPress={() => goToDraftScreen(null)} >+</Button>
                </HStack>
                <HStack mb={4} mt={2} justifyContent={"space-between"}>
                    <Button variant="link" p={0} colorScheme={reminderType === "upcoming" ? "primary" : "light" } onPress={() => setReminderType("upcoming")}>Upcoming</Button>
                    <Button variant="link" colorScheme={reminderType === "template" ? "primary" : "light" } p={0} onPress={() => setReminderType("template")}>Templates</Button>
                    <Button variant="link" colorScheme={reminderType === "past" ? "primary" : "light" } p={0} onPress={() => setReminderType("past")}>Past</Button>
                </HStack>
                <Input mb={2} rounded={"full"}   InputLeftElement={<Icon as={Feather} name="search" ml="2" size="4" />}  rightElement={<Button variant={"ghost"} onPress={updateSearch}>Search</Button>} size="lg" placeholder="Search reminders..." value={searchQuery} variant="filled" onChangeText={(text) => setSearchQuery(text) }/>
                {!currReminders && <Spinner />}
                {(currReminders?.length === 0) && <View><Text color="coolGray.600">No {reminderType} reminders...</Text>
                <HStack mt={2}>
                    <Button variant="subtle" rounded="full" onPress={() => goToDraftScreen()}>Create reminder</Button>
                </HStack>
                </View>}
                <FlatList w={"100%"} data={getRemindersForType()}
                    renderItem={renderReminder} />
            </View>
        }
        return null;
    }


    return <Layout>
        {/* {(!memberMetrics || !reminders) && <Heading>Loading...</Heading>} */}
        <View position="absolute" bottom={0} right={0} zIndex={5} mb={4}>
            <Button size="lg" rounded={"full"} onPress={() => goToDraftScreen(null)} leftIcon={<Icon as={<AntDesign name="plus" />} />}>Create</Button>
        </View>
        <FlatList data={[0, 1, 2]} w="100%"
            keyboardShouldPersistTaps="always"
            showsVerticalScrollIndicator={false}
            refreshing={refreshing} onRefresh={handleRefresh}
            renderItem={(item) => renderItem(item)}
            onEndReachedThreshold={0.3}
            onEndReached={handleLoadMore}
            ListFooterComponent={() => loadingMore ? <Spinner /> : null}
        />

        <InitialReminderCreateModal isOpen={showInitialReminderCreate} onConfirm={onNotificationConfirm} onCancel={onIntialReminderCreateClose} />
    </Layout>  
}
