import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { getAuth, createUserWithEmailAndPassword, signInWithCredential, GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, UserCredential } from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Heading, View, Text, Input, Button, Pressable, HStack } from "native-base";
import * as AppleAuthentication from 'expo-apple-authentication';
import { sha256 } from 'js-sha256';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { FirebaseError } from "firebase/app";
import { Alert } from "react-native";
import SimpleModal from "../components/SimpleModal";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import { getCurrentUser } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserAccessJson } from "../store";
import BaseApiConfigProvider from "../api/BaseApiConfigProvider";
import { Platform } from 'react-native'
import UniversalSignInWithGoogle from "../components/UniversalSignInWithGoogle";
import WebSigninWithApple from "../components/WebSignInWithApple";
import AnalyticsManager from "../utils/AnalyticsManager";
import { LoadingModal } from "../components/LoadingModal";
import Purchases from "react-native-purchases";
import { Linking } from "react-native";

const NONCE_STRING = "myreminderbaseuirandomstring"

export default function SignupScreen(props) { 

    const userApi = new GenericViewSetAPI("user")
    const dispatch = useDispatch()
    const config = useSelector((state: any) => state.config)

    const [isLogin, setIsLogin] = useState(true)

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signInLoading, setSignInLoading] = useState(false);
    const [userCredential, setUserCredential] = useState(null);
    const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
    const [appleCredential, setAppleCredential] = useState(null);
    const [googleCredential, setGoogleCredential] = useState(null);
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (isLogin) { 
            // set header title to be Sign in 
            props.navigation.setOptions({headerTitle: "Sign in"})
        } else { 
            // set header title to be Sign up 
            props.navigation.setOptions({headerTitle: "Sign up"})
        }
    }, [isLogin])


    const onGoogleCredential = async (credential) => {
        setLoading(true)
        AnalyticsManager.logEvent("sign_in", {method: "google"})
        setGoogleCredential(credential);
        console.log("basic_creds", credential)
        try { 
            let userCredential = credential;
            if (Platform.OS !== "web") { 
                userCredential = await signInWithCredential(getAuth(), credential);
            }
            await handlePostCredential(userCredential);
        } catch (e) {
            handleFirebaseLoginError(e);
        }
        setLoading(false)
    }

    const createAccountFromCredential = async () => {
        setCreateAccountModalOpen(false)
        setLoading(true);
        await getTokenFromUserCredential(userCredential, true)
        setLoading(false)
    }

    const getTokenFromUserCredential = async (userCredential: UserCredential, forceCreateUser: boolean, displayNameOverride = null) => {
        // console.error(userCredential)
        const body = {firebase_uid: userCredential.user.uid}
        if (forceCreateUser) { 
            body["auto_create"] = true
        }
        const tokenResp = await userApi.nonStandard("POST", "token", body)
        console.log("Token response")
        console.log(tokenResp)
        if (tokenResp && !tokenResp.error) {
            const token = tokenResp.token;
            await BaseApiConfigProvider.saveUserToken(token);
            const accUser = tokenResp.user
            if (accUser) { 
                AnalyticsManager.logEvent("sign_in_success")
                AnalyticsManager.identify(accUser.id)
                const userAccessJson = await userApi.nonStandard("GET", "feature_access")
                if (userAccessJson && !userAccessJson.error) {
                    dispatch(setUserAccessJson(userAccessJson))
                }
                dispatch(setUser(accUser))
                Purchases.logIn(accUser.revenuecat_user_id)
                Purchases.setAttributes({"user_id": accUser.id, "email": accUser.email})
                continueWithLogin(tokenResp, accUser, userCredential, displayNameOverride)
            }
        } else { 
            if (tokenResp.error && tokenResp.error?.detail == "User does not exist") {
                console.log("USER ACCOUNT DOES NOT EXIST ON BACKEND")
                setCreateAccountModalOpen(true)
            }  
        }
    }

    const isSignup = () => {
        return !isLogin
    }

    const handleAppleSignIn = async () => {
        setLoading(true)
        // TOOD: Check if credential already exists and if so just use that instead.
        AnalyticsManager.logEvent("sign_in", {method: "apple"})
        try {
            const credential = await AppleAuthentication.signInAsync({
            requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
            ],
            nonce: sha256(NONCE_STRING)
            });
            setAppleCredential(credential)
            console.log("Apple credential====", credential)
            const oAuth = new OAuthProvider('apple.com');
            try { 
                let displayNameOveride = null;
                if (credential.fullName.givenName || credential.fullName.familyName) {
                    let name = ""
                    if (credential.fullName.givenName) { 
                        name += credential.fullName.givenName
                    }
                    if (credential.fullName.familyName) { 
                        name += " " + credential.fullName.familyName
                    }
                    displayNameOveride = name
                }
                const userCredential = await signInWithCredential(getAuth(), oAuth.credential({idToken: credential.identityToken, rawNonce: NONCE_STRING }));
                await handlePostCredential(userCredential, displayNameOveride);
            } catch (e) { 
                handleFirebaseLoginError(e);
            }

        } catch (e) {
            if (e.code === 'ERR_REQUEST_CANCELED') {
            // handle that the user canceled the sign-in flow
            } else {
            // handle other errors
            }
        }
        setLoading(false)
    }

    const onAppleCredential = async(inputCredential) => {
        setAppleCredential(inputCredential)
        const oAuth = new OAuthProvider('apple.com');
        try { 
            console.log("Apple credential====", inputCredential)
            const userCredential = await signInWithCredential(getAuth(), oAuth.credential({idToken: inputCredential.identityToken, rawNonce: NONCE_STRING }));
            console.log("user credential", userCredential)
            await handlePostCredential(userCredential);
        } catch (e) { 
            handleFirebaseLoginError(e);
        }
    }
    

    const handlePostCredential = async(credential: UserCredential, displayNameOverride = null) => {
        console.log(credential)
        console.log(`User signed in with name ${credential.user.displayName} and email ${credential.user.email}`)
        setUserCredential(credential)
        const token = await getTokenFromUserCredential(credential, true, displayNameOverride);
    }

    const handleFirebaseLoginError = (e) => { 
        AnalyticsManager.logEvent("firebase_auth_error", {error: e.code})
        console.log(e)
        console.log("HANDLING ERROR " + e.code)
        if (e instanceof FirebaseError) { 
            const fbError: FirebaseError  = e;
            if (fbError.code == "auth/user-not-found") {
                // Show error and give option to create account.
                Alert.alert("Sorry, we couldn't find an account with that email address. Please try again or create an account.");
            } else if (fbError.code == "auth/invalid-email") { 
                Alert.alert("Please enter a valid email address");
            } else if (fbError.code == "auth/wrong-password") {
                Alert.alert("The password you entered is incorrect. Please try again.")
            } else if (fbError.code == "auth/email-already-in-use") {
                Alert.alert("An account with that email address already exists. Please try again or log in.")
            } else if (fbError.code == "auth/operation-not-allowed") {
            } else if (fbError.code == "auth/weak-password") {
                Alert.alert("Please enter a stronger password.")
            } else if (fbError.code == "auth/invalid-credential") {
            } else if (fbError.code == "auth/account-exists-with-different-credential") {
            } else if (fbError.code == "auth/missing-password") { 
                Alert.alert("Please enter a password")
            } else { 
                Alert.alert("Sorry, there was an error logging in. Please try again.")
            }
            return fbError.code
        }
    }

    const handleRawLogin = async () => {
        AnalyticsManager.logEvent("sign_in", {method: "email_password"})
        setLoading(true);
        try { 
            var result = null;
            if (isLogin) {
                result = await signInWithEmailAndPassword(getAuth(), email, password);
            } else { 
                result = await createUserWithEmailAndPassword(getAuth(), email, password);
            }
            setUserCredential(result)
            await getTokenFromUserCredential(result, !isLogin)
            console.error(result);
            // continueWithLogin();
        } catch (e) { 
            handleFirebaseLoginError(e);
        }
        setLoading(false);
    }

    const continueWithLogin = (createResp, user, userCredential=null, displayNameOveride=null) => {
        if (!createResp.created && createResp.user.name) { 
            console.error("PUSHING TO ROOT")
            props.navigation.navigate("Root")
        } else {
            props.navigation.navigate("OnboardUser", {user: user, userCredential: userCredential, displayName: displayNameOveride })
        }
    }

    const continueWithoutAccount = () => {
        props.navigation.push("Root")
    }

    return <Layout scrollView>
        <KeyboardAwareScrollView style={{width: "100%"}} keyboardShouldPersistTaps="always">
            <View w="100%" mt={40} mb={4} justifyContent={"center"}>
                <Heading mb={1} textAlign={"center"}>Reminderbase</Heading>
                {isLogin && <Text textAlign={"center"}>Sign in to your account</Text>}
                {!isLogin && <Text textAlign={"center"}>Create an account</Text>}
            </View>
            <View  w="100%" flex={1} alignItems={"center"}>
                {isLogin && config?.guest.access_allowed && <Button w="80%" variant="subtle" colorScheme={"coolGray"} onPress={continueWithoutAccount}>Continue without account</Button>}
                <UniversalSignInWithGoogle onCredential={(cred) => onGoogleCredential(cred)} onError={(e) => handleFirebaseLoginError(e) } />
                {Platform.OS == "ios" && <AppleAuthentication.AppleAuthenticationButton
                    buttonType={ isLogin ? AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN : AppleAuthentication.AppleAuthenticationButtonType.SIGN_UP}
                    buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                    cornerRadius={5}
                    // style={styles.button}
                    style={{ width: '80%', height: 44, marginTop: 10 }}
                    onPress={handleAppleSignIn}
                />}
                
                {/* {Platform.OS == "web" && <WebSigninWithApple w="80%" mt={2} onCredential={(cred) => onAppleCredential(cred)} onError={(e) => handleFirebaseLoginError(e)}/>} */}
                
                <Text mb={2} mt={2} color="coolGray.600">--- or ---</Text>

                <View w="80%">
                    <Input size="lg" w="100%" mb={1} placeholder="Email" value={email} onChangeText={(txt) => setEmail(txt)} keyboardType="email-address" />
                    <Input size="lg" placeholder="Password" type="password" value={password} onChangeText={(text) => setPassword(text)}/>
                    <Button isLoading={signInLoading} mt={2} onPress={() => handleRawLogin()}>{isLogin ? "Log in" : "Create account"}</Button>
                </View>

            </View>

            <HStack alignSelf={"center"} mb={4} mt={4}>
                    <Pressable onPress={() => setIsLogin(true)}><Text underline>Sign in</Text></Pressable>
                    <Text ml={1} mr={1}>or</Text>
                    {<Pressable onPress={() => setIsLogin(false)}><Text underline>create an account</Text></Pressable>}
            </HStack>

            <Text fontSize={"xs"} textAlign={"center"}>
                By signing in you agree to our <Text color={"blue.500"} onPress={() => Linking.openURL("https://www.reminderbase.com/terms-of-service")}>Terms of Service</Text> and <Text color={"blue.500"} onPress={() => Linking.openURL("https://www.reminderbase.com/privacy-policy")}>Privacy Policy</Text>
            </Text>


            </KeyboardAwareScrollView>

             <LoadingModal visible={loading || signInLoading}/>

            <SimpleModal size="lg" headerTitle="No existing account" isOpen={createAccountModalOpen} onClose={() => setCreateAccountModalOpen(false)} getFooter={() => <HStack space={2}>
                <Button variant="subtle" onPress={() => createAccountFromCredential()}>Create account</Button>
                <Button variant="subtle" colorScheme={"dark"} onPress={() => setCreateAccountModalOpen(false)}>Cancel</Button>
            </HStack>}>
                <View>
                    <Text fontSize="md">It seems like no account exists for your details. Would you like to create one?</Text>
                </View>
            </SimpleModal>
        </Layout>
}