import Layout from "../components/Layout";
import { HStack, View, Text, Badge, Input, Divider, TextArea, Heading, Button, Pressable, Modal, FlatList, useLayout, KeyboardAvoidingView, Stack, Box } from "native-base";
import { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { Alert, InputAccessoryView, Keyboard, Platform, TextInput, Image } from "react-native";
import { Actionsheet } from "native-base";
import Placehoder from '../Constants/Placeholder'
import SimpleModal from "../components/SimpleModal";
import ContactAddActionSheet from "../components/ContactAddActionSheet";
import { Feather } from '@expo/vector-icons'; 
import { getContactList, getContactName, getContacts, getLimitedText, pickImage, showAlert } from "../utils";
import GenericViewSetAPI from "../api/GenericViewSetAPI";
import ConfirmModal from "../components/ConfirmModal";
import { hasSku } from "../utils/AccessUtil";
import { useSelector } from "react-redux";
import SimpleActionSheet from "../components/SimpleActionSheet";
import { useFocusEffect } from "@react-navigation/native";
import Analytics from "../utils/AnalyticsManager";
import lodash from 'lodash'


const ADD_NEW_RECIPIENT_STR = "+ Add as new recipient"

export type ReminderMessageType = "email" | "text" | "email_or_text" | "text_or_email"

export function getReadableStringForMessageType(messageType: ReminderMessageType) { 
    if (messageType === "email" || messageType === "text") {
        return messageType.toString();
    }
    else if (messageType === "email_or_text") { 
        return "email or text"
    } else if (messageType === "text_or_email") {
        return "text or email"
    }
}

export default function ReminderDraftScreen(props) { 

    const user = useSelector((state: any) => state.user)
    const config = useSelector((state: any) => state.config)
    const localContactsMap = useSelector((state: any) => state.localContactsMap)

    const userAccessJson = useSelector((state: any) => state.userAccessJson)
    const reminderApi = new GenericViewSetAPI("reminder")
    const contactsApi = new GenericViewSetAPI("contact")
    const contactListApi = new GenericViewSetAPI("contact_list")
    const recipientApi = new GenericViewSetAPI("recipient")


    const reminder = props.route.params?.reminder
    const reminderId = props.route.params?.reminderId
    const calendarId = props.route.params?.calendarId
    const start = props.route.params?.start

    const inputAccessoryViewID = 'draft';
    const isEdit = reminder !== null

    const [saving, setSaving] = useState(false)
    const [title, setTitle] = useState(reminder?.title ?? props.route?.params?.title ?? "")
    const [text, setText] = useState(reminder?.message || "")
    const [recipients, setRecipients] = useState(reminder?.recipients || [])
    const [reminderMessageType, setReminderMessageType] = useState<ReminderMessageType>(reminder?.message_type || "text_or_email")
    const [template, setTemplate] = useState(reminder?.template || "")
    const [templateObj, setTemplateObj] = useState(null);
    const [contacts, setContacts] = useState(reminder?.contacts ?? getContacts(reminder) ?? [])
    const [contactList, setContactList] = useState(reminder?.contact_list ?? getContactList(reminder) ?? null)
    const [reminderTypeModalOpen, setReminderTypeModalOpen] = useState(false)
    const [templateModalOpen, setTemplateModalOpen] = useState(false)
    const [allTemplates, setAllTemplates] = useState([])
    const [image, setImage] = useState(null)
    const [imageModalOpen, setImageModalOpen] = useState(false)
    const [imageEditOpen, setImageEditOpen] = useState(false)
    const [keyboardShow, setKeyboardShow] = useState(false)
    const [activeTextType, setActiveTextType] = useState(null)
    const [autoCompleteContacts, setAutoCompleteContacts] = useState([])
    const [enteredContactText, setEnteredContactText] = useState("")

    const getToView = () => {
        const contactOverflows = contacts.length > 2
        const primaryContactNames = contacts.slice(0, 2).map(item => getContactName(item, true, localContactsMap)).join(", ")
        const nonContactView = <Text color="blue.500">{primaryContactNames}</Text>
        var contactView = nonContactView
        var additionalView = null

        if (contactList || contactOverflows) { 
            var additionalCount = contacts.length
            if (!contactList && contactOverflows) { 
                additionalCount = contacts.length - 2
            }
            if (contacts && additionalCount > 0) { 
                additionalView = <Pressable onPress={() => handleGoToRecipientScreen()}>
                    <Text color="blue.500" > + {additionalCount}</Text>
                </Pressable>
            }

            if (contactList) { 
                contactView = null;
            }
        }
        return <HStack>
                <Button variant="subtle" leftIcon={<Feather name="edit-3" size={14} />} mr={2} pt={0} pb={0} 
                    onPress={() => handleGoToRecipientScreen()}>edit</Button> 
                {contactList && <Text color={"blue.500"}>{contactList.name}</Text>}
                {contactView && contactView}
                {additionalView && additionalView}
            </HStack>
    }

    useFocusEffect(useCallback(() => {
        Analytics.logEvent("view_page", {screen: "ReminderDraft", reminderId: reminderId, calendarId: calendarId})
    }, []))

    const onContactListsUpdated = (contactLists) => { 
        Analytics.logEvent("contact_list_added", {reminderId: reminderId})
        // Alert.alert(JSON.stringify(contactLists))
        console.log(` updated contactList is ${contactLists}`)
        // setContactLists(contactLists)
        setContactList(contactLists[0])
    }

    const onContactsUpdated = (newContacts, append=false) => {
        Analytics.logEvent("contact_added", {reminderId: reminderId})
        console.log("REMINDER DRAFT SCREENNN BABEHHH")
        console.error(newContacts)
        if (append) { 
            setContacts([...contacts, ...newContacts])
        } else { 
            setContacts(newContacts)
        }

    }

    const handleGoToRecipientScreen = () => { 
        props.navigation.push("Recipient", {reminder: reminder, contactLists:  contactList ? [contactList] : null, contacts: contacts, onContactListsUpdated: onContactListsUpdated, onContactsUpdated: onContactsUpdated })
    }
    
    const templateOnRender = (item) => {
        return <View  mt={2} mb={2}>
            <Text fontSize="lg">{item.title}</Text>
        </View>
    }

    const templateOnSelect = (obj) => {
        // TODO: If there is already text entered -- show a popup that asks user to confirm if they want to lose the current text data.
        setTemplateObj(obj)
        if (title.length != 0 || text.length !== 0) {
            setTemplateModalOpen(true)
        } else {
            integrateTemplate(obj)
        }
    }

    const integrateTemplate = (obj) => {
        setText(obj.message)
        setTitle(obj.title)
        setTemplateModalOpen(false)
    }

    const keyboardDidShow = (event) => {
        console.log("KEYBOARD SHOWED")
        setKeyboardShow(true)
    }
              
    const keyboardDidHide = (event) => {
        setKeyboardShow(false)
    }

    const openTemplateView = () => {
        props.navigation.push("SelectList", { title: "Select template", items: allTemplates, renderItem: templateOnRender, onSelect: templateOnSelect })
    }

    const hydrateTemplates = async () => {
        const response = await reminderApi.query({type: "template"})
        console.error(response)
        if (response && !response.error) { 
            setAllTemplates(response.results)
        }
    }
 
    const getTemplateView = () => {
        //TODO: Add template selection logic
        return <View>
            <Button pt={0} pb={0}  colorScheme={"amber"} variant="subtle" onPress={() => openTemplateView()}>{getLimitedText(templateObj?.title ?? "No template", 12)}</Button>
        </View>
    }

    const handleReminderMessageTypeSelect = (messageType: ReminderMessageType) => {
        setReminderTypeModalOpen(false)
        setReminderMessageType(messageType)
    }

    useLayoutEffect(() => {
        props.navigation.setOptions({headerRight: () => <Button variant="ghost" onPress={goToSettingScreen}>{reminder ? "Setting" : "Continue"}</Button>})
    })

    useEffect(() => {
        hydrateTemplates()
        if (Platform.OS === "android") { 
            const listener = Keyboard.addListener('keyboardDidShow', keyboardDidShow);
            const listener2 = Keyboard.addListener('keyboardDidHide', keyboardDidHide);
            return () =>  {
                listener.remove();
                listener2.remove();
            }
        }
    }, [])

    const goToSettingScreen = async () => {
        console.log('REMINDER IS ', reminder)
        const updatedReminder = {
            ...reminder,
            title: title,
            message: text,
            image_url: null ?? reminder?.image_url, 
            message_type: reminderMessageType
        }
        
        let parsedContacts = []
        if (enteredContactText) {
            parsedContacts = await onPreviewContactSelected({type: "add_new_contact", name: ADD_NEW_RECIPIENT_STR})
        }

        console.error("parsedContacts ARE", parsedContacts)
        
        
        if (!updatedReminder?.id) { 
            updatedReminder.contact_lists = contactList ? [contactList] : null,
            updatedReminder.contacts = [...contacts, ...parsedContacts]
            updatedReminder.scheduled_at = props.route.params?.start
            updatedReminder.expires_at = props.route.params?.end
            updatedReminder.calendar_event_id = calendarId
            if (updatedReminder.contacts.length == 0 && !updatedReminder.contact_lists) {
                Analytics.logEvent("reminder_client_error", {error: "no_recipients"})
                Alert.alert("Error", "Please add a recipient")
                return
            }
        }
        console.log(updatedReminder)
        setEnteredContactText("")
        props.navigation.navigate("ReminderSetting", {reminder: updatedReminder, reminderId: reminder?.id, image: image})
    }

    const handleImageAdd = async () => {
        if (hasSku("upload_reminder_image", userAccessJson)) { 
            const selectedImage = await pickImage();
            console.log(selectedImage)
            if (selectedImage) {
                const uri = selectedImage.uri; 
                // selectedImage.
                setImage(selectedImage)
            }
        } else { 
            props.navigation.push("Plans", { sku: "reminder attachments" })
        }
    }

    const hasImage = () => {
        return image || reminder?.image_url
    }

    const addContactListRecipient = async(contactList) => {
        console.log(contactList)
        console.log(reminder?.id)
        var resp = null;
        if (contactList.id) {
            resp = await recipientApi.create({reminder_id: reminder.id, contact_list: contactList.id})
        } else {
            resp = await recipientApi.create({reminder_id: reminder.id, contact_list_body: contactList})
        }
        if (!resp || resp.error) {
            Alert.alert("Error", "Sorry, we couldn't add that contact list to the recipients")
        } else { 
            onContactListsUpdated([contactList])
        }
        return resp
    }


    const addContactRecipient = async(contact) => {
        var resp = null;
        if (contact.id) { 
            resp = await recipientApi.create({reminder_id: reminder.id, contact: contact.id})
        } else {
            resp = await recipientApi.create({reminder_id: reminder.id, contact_body: contact})
        }
        if (!resp || resp.error) { 
            Alert.alert("Error", "Sorry, we couldn't add that contact to the recipients")
        } else { 
            onContactsUpdated([contact], true)
        }
        return resp
    }



    const handleEnteredTextSearch = async(text) => {
        const contactsSearch = contactsApi.query({q: text, page_size: 3})
        const contactListSearch = contactListApi.query({name: text, page_size: 3})
        const [contactsResp, contactListResp] = await Promise.all([contactsSearch, contactListSearch])
        console.log(contactsResp)
        console.log(contactListResp)
        const potentialRecipients = []
        for (const contact of contactsResp.results) { 
            const potentialRecipient = {type: "contact", name: contact.name, id: contact.id, contact: contact}
            potentialRecipients.push(potentialRecipient)
        }
        for (const contactList of contactListResp.results) {
            const potentialRecipient = {type: "contact_list", name: contactList.name, id: contactList.id, contactList: contactList}
            potentialRecipients.push(potentialRecipient)
        }
        setAutoCompleteContacts(potentialRecipients)
    }

    const enteredTextHandler = useCallback(lodash.debounce(handleEnteredTextSearch, 300), [])


    const onEntereContactText = (text) => {
        setEnteredContactText(text) 
        enteredTextHandler(text)
    }


    const isPhone = (text) => {
        return text.match(/(?:[-+() ]*\d){10,13}/)
    }

    const onPreviewContactSelected = async (contact) => {
        setEnteredContactText("")
        if (contact.type == "contact") { 
            if (!reminder?.id) { 
                onContactsUpdated([contact.contact], true)
            } else { 
                await addContactRecipient(contact.contact)
            }
        } else if (contact.type == "contact_list") {
            if (!reminder?.id) {
                onContactListsUpdated([contact.contactList])
            } else { 
                await addContactListRecipient(contact.contactList)
            }
            return []
        } else if (contact.type == "add_new_contact") {   
            // Handle user typing multiple entries with email or phone number.
            const localEnteredText = enteredContactText.replace(/\s/g, '');
            const items = localEnteredText.split(",")
            console.error("items are", items)
            let parsedContacts = []
            for (const item of items) {        
                const isEmail = item.match(/.+@.+\..+/)
                const contact: any = {}
                if (isEmail) { 
                    contact.email = item
                } else if (isPhone(item)) {
                    // regex to get phone number digits
                    const number = item.match(/\d+/g).join('')
                    contact.mobile = number
                } else {
                    showAlert("Please enter a phone number or email")
                    return
                }
                parsedContacts.push(contact)
            }
            if (!reminder?.id) {
                onContactsUpdated(parsedContacts, true)
            } else { 
                for (const selectedContact of parsedContacts) {
                    await addContactRecipient(selectedContact)
                }
            }
            return parsedContacts
        }
    }

    const getKeyboardView = () => {    
        let firstNameOnPressFn = () => setText(text + "{first_name} ")
        let lastNameOnPressFn = () => setText(text + "{last_name} ")
        if (activeTextType == "title") { 
            firstNameOnPressFn = () => setTitle(title + "{first_name} ")
            lastNameOnPressFn = () => setTitle(title + "{last_name} ")
        }
        return <HStack w="100%" bg="white" display="flex" alignItems={"flex-end"} justifyContent={"space-between"}>
            <Button variant="link" onPress={firstNameOnPressFn}>{"{first_name}"}</Button>
            <Button variant="link" onPress={lastNameOnPressFn}>{"{last_name}"}</Button>
            <Button w="25%" variant="link" onPress={() => {
                Keyboard.dismiss()}}>Done</Button>
        </HStack>
    }

    const getAutoCompleteStr = (item) => {
        if (item.type == "add_new_contact") {
            return item.name
        } else {
            return getContactName(item.type == "contact" ? item.contact : item.contactList, true, localContactsMap)
        }
    }

    return <Layout>
        <View mt={3}> 
        <Input placeholder="Enter name, contact list, phone number or email...." mb={2} value={enteredContactText} variant="filled" size={"md"} onChangeText={(e) => onEntereContactText(e) }/>
        {enteredContactText && [...autoCompleteContacts, {type: "add_new_contact", name: ADD_NEW_RECIPIENT_STR}].map((item) => <Pressable onPress={() => onPreviewContactSelected(item)} justifyContent={"center"} h="30px" mb={2} ><Text color={item.type == "add_new_contact" ? "blue.800" : null} mb={2}>{getAutoCompleteStr(item)}</Text><Divider/></Pressable>)}
        {/* <FlatList  height={"20px"} contentContainerStyle={{height: 10}} data={["a", "b"]} renderItem={(item) => <Text>{item.item}</Text>} /> */}
        {(contacts.length > 0 || contactList) && <HStack space={2} mb={2}>
            <Text>To: </Text>
            {getToView()}
        </HStack>}
        <HStack justifyContent={"space-between"}>
            <HStack space={2}>
                <Text>Template: </Text>
                {getTemplateView()}
            </HStack>
            {/* Allow selection for type */}
            <HStack>
                <Text>Type: </Text>
                <Button colorScheme={"amber"} variant={"subtle"} pt={0} pb={0} onPress={() => setReminderTypeModalOpen(true)}>{getReadableStringForMessageType(reminderMessageType)}</Button>
            </HStack>
        </HStack>

        <HStack mt={2} space={2}>
            <Text>Attachment:</Text>
            <HStack>
                <Button leftIcon={<Feather name="edit-3" size={14} />} variant="subtle" pt={0} pb={0} onPress={() => hasImage() ? setImageEditOpen(true) : handleImageAdd()}>{image ? "edit" : "add"}</Button>
                {hasImage() && <Button variant="coolGray" colorScheme={"dark"} _text={{underline: true}} pt={0} pb={0} onPress={() => setImageModalOpen(true)}>1 image</Button>}
            </HStack>
        </HStack>

        <View mt={4} w="100%">
            <Input size="2xl" maxLength={config?.reminder?.title_char_limit ?? 50} variant="underlined" w="100%" onFocus={() => setActiveTextType("title")} inputAccessoryViewID={inputAccessoryViewID} placeholder="Enter reminder title..." value={title} onChangeText={(text) => setTitle(text)} />

            <TextInput multiline style={{ marginTop: 10, fontSize: 16, height: 200}} 
                        value={text} 
                        maxLength={config.reminder?.message_char_limit ?? 300}
                        onFocus={() => setActiveTextType("text")}
                        autoCorrect={false}
                        
                        // scrollEnabled={true}
                        inputAccessoryViewID={inputAccessoryViewID}
                        numberOfLines={Platform.OS === "web" ? 4 : null}
                        placeholderTextColor="gray" 
                        onChangeText={(text) => setText(text)}
                        placeholder={"Enter reminder message..."}></TextInput>
        </View>

        {Platform.OS == "android" && keyboardShow && <KeyboardAvoidingView>
            {getKeyboardView()}
        </KeyboardAvoidingView>}

        {Platform.OS === "ios" && <InputAccessoryView nativeID={inputAccessoryViewID}>
            {getKeyboardView()}
        </InputAccessoryView>}

        <Actionsheet isOpen={reminderTypeModalOpen} onClose={() => setReminderTypeModalOpen(false)}>
            <Actionsheet.Content>
                <Actionsheet.Item onPress={() => handleReminderMessageTypeSelect("email")}>
                    <View>
                        <Text fontSize="md">{getReadableStringForMessageType("email")}</Text>
                        <Text fontSize="sm" color={"coolGray.500"}>Sends an email to contacts with an email</Text>
                    </View>
                </Actionsheet.Item>
                <Actionsheet.Item onPress={() => handleReminderMessageTypeSelect("text")}>
                    <View>
                        <Text fontSize="md">{getReadableStringForMessageType("text")}</Text>
                        <Text fontSize="sm" color={"coolGray.500"}>Sends a text to contacts with a phone number</Text>
                    </View>
                </Actionsheet.Item>
                <Actionsheet.Item onPress={() => handleReminderMessageTypeSelect("text_or_email")}>
                    <View>
                        <Text fontSize="md">{getReadableStringForMessageType("text_or_email")}</Text>
                        <Text fontSize="sm" color={"coolGray.500"}>Sends a text to contacts with a phone number, and an email to contacts with an email and no phone number</Text>
                    </View>
                </Actionsheet.Item>
                <Actionsheet.Item onPress={() => handleReminderMessageTypeSelect("email_or_text")}>
                    <View>
                        <Text fontSize="md">{getReadableStringForMessageType("email_or_text")}</Text>
                        <Text fontSize="sm" color={"coolGray.500"}>Sends an email to contacts with an email, and a text to contacts with a phone number but no email</Text>
                    </View>
                </Actionsheet.Item>
            </Actionsheet.Content>
        </Actionsheet>


        <ConfirmModal isOpen={templateModalOpen} onClose={() => {
            setTemplateObj(null)
            setTemplateModalOpen(false)
        }} 
            headerTitle={"Use template?"} text="The current message you have as your reminder will be replaced" confirmLabel="Confirm" 
        onConfirm={() => integrateTemplate(templateObj) } />


        <SimpleModal isOpen={imageModalOpen} onClose={() => setImageModalOpen(false)} headerTitle="View image">
            <Image source={{uri: image ? image.uri : reminder?.image_url }} height={200} width={200} /> 
        </SimpleModal>

        <SimpleActionSheet isOpen={imageEditOpen} onClose={() => setImageEditOpen(false)} 
        items={[
            {
                label: "Remove image", 
                onSelect: () => {
                    setImageEditOpen(false)
                    setImage(null)}
                }, 
            {
                label: "Change image", 
                onSelect: () => handleImageAdd()
            }]
        } />
{/* 
        <SimpleModal isOpen={templateModalOpen} onClose={() => setTemplateModalOpen(false)}>
            <FlatList data={Placehoder.TEMPLATES_RESP.data} renderItem={(item) => templateOnRender(item)}></FlatList> 
        </SimpleModal> */}
    </View>
    </Layout>
}