import { Badge, Button, HStack, Pressable, Switch, Text } from "native-base"
import { useEffect, useState } from "react"


export default function PlanCard(props) {

    const [longerPlanSelected, setLongerPlanSelected] = useState(true)
    const [selectedPackageIndex, setSelectedPackageIndex] = useState(0)
    const [packageData, setPackageData] = useState(props.relationshipMap[props.item][selectedPackageIndex])
    const [packages, setPackages] = useState(props.relationshipMap[props.item])

    useEffect(() => {
        setPackageData(props.relationshipMap[props.item][selectedPackageIndex])
    }, [selectedPackageIndex])

    console.log("packages are ", packages)
    console.log("package data is", packageData)
    console.log("plan package map is", props.planPackageMap)
    const plan = props.planPackageMap[packageData.identifier]
    const relationships = props.relationshipMap[plan.revenue_cat_id]
    console.log("relationships length are ", relationships.length)
    const multiProduct = relationships?.length > 1


    const getReadableStringFromNumMonths = (subscriptionPeriod) => {
        // write a function that converts P1M to per month and P3M to per 3 months
        if (subscriptionPeriod === "P1M") {
            return "per month"
        }
        if (subscriptionPeriod === "P3M") {
            return "per 3 months"
        } 
        if (subscriptionPeriod === "P1Y") {
            return "per year"
        }
        return ""
    }

    const getTimeLabel = (subscriptionPeriod) => { 
        if (subscriptionPeriod === "P1M") {
            return "Monthly"
        }
        if (subscriptionPeriod === "P3M") {
            return "3 Month"
        } 
        if (subscriptionPeriod === "P1Y") {
            return "Annual"
        }
        return ""
    }

    const getNumberOfMonths = (period) => {
        return period === "P1M" ? 1 : period === "P3M" ? 3 : period === "P6M" ? 6 : period === "P1Y" ? 12 : 1
    }

    const getPriceStr = () => { 
        if (packageData.product.subscriptionPeriod == "P1M") {
            return packageData.product.priceString + " per month"
        } else { 
            return "$" + getPricePerMonth() + " per month"
        }
    }

    const getPricePerMonth = () => { 
        const val = packageData.product.price / getNumberOfMonths(packageData.product.subscriptionPeriod)
        return val.toFixed(2)
    }
    
    const calculatePriceSaving = () => {
        const longerPeriod = packages[0].product.subscriptionPeriod
        const longerPrice = packages[0].product.price
        const pricePerMonth = longerPrice / getNumberOfMonths(longerPeriod)
        const shorterPeriod = packages[1].product.subscriptionPeriod
        const shorterPrice = packages[1].product.price
        const shorterPricePerMonth = shorterPrice / getNumberOfMonths(shorterPeriod)
        const savings =  ((shorterPricePerMonth - pricePerMonth)/ shorterPricePerMonth) * 100
        return "Save " + savings.toFixed(0) + "%"
    }

    if (!plan) {
        return null
    }
    return <Pressable onPress={() => props.setSelectedPlan(packageData)} borderColor={"coolGray.600"} mt={4} borderWidth={1} p="2" rounded={"lg"}>
        {props.currentPlanIdentifiers && props.currentPlanIdentifiers.includes(packageData.product?.identifier) && <HStack mb={2}><Badge colorScheme={"info"}>Current plan</Badge></HStack>}
        <Text bold fontSize={"md"}>{packageData.product?.title}</Text>
        {multiProduct && <HStack alignItems={"center"}>
            <Text>{getTimeLabel(packages[1].product.subscriptionPeriod)}</Text>
            <Switch size="sm" isChecked={selectedPackageIndex == 0} onToggle={() => setSelectedPackageIndex(selectedPackageIndex > 0 ? 0 : 1)}/>
            <Text>{getTimeLabel(packages[0].product.subscriptionPeriod)}</Text>
            <Badge ml={2} colorScheme={"green"}>{calculatePriceSaving()}</Badge>
            {/* <Button colorScheme={"green"} ml={2} variant="subtle" size="xs">{calculatePriceSaving()}</Button> */}

            </HStack>}
        <Text fontSize="sm">{getPriceStr()}</Text>
        {plan.marketing_features && plan.marketing_features.split(",").map((feature) => <Text fontSize="xs">{feature}</Text>)}
    </Pressable>

}